/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { Stack } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import type { TransformedTokenGrouped } from '../types';

import TokenDefinition from './token-definition';
import TokenDescription from './token-description';
import TokenLifecycle from './token-lifecycle';

const tokenRowExtensionsStyles = css({
	display: 'flex',
	gap: token('space.200', '16px'),
	borderBlockEnd: 'none',
	paddingBlockEnd: token('space.100', '8px'),
	[media.below.sm]: {
		flexWrap: 'wrap',
		paddingBlockEnd: token('space.300', '24px'),
	},
});

const tokenRowStackStyles = css({
	display: 'flex',
	gap: token('space.200', '16px'),
	backgroundColor: token('elevation.surface'),
	marginBlockEnd: token('space.200', '16px'),
	paddingBlockEnd: token('space.200', '16px'),
	[media.below.sm]: {
		flexWrap: 'wrap',
		paddingBlockEnd: token('space.300', '24px'),
	},
});

const rowBorderStyles = css({
	borderBlockEnd: `${token('border.width', '1px')} solid ${token('color.border')}`,
});

const resetTableDefaultStyles = css({
	padding: 0,
});

interface TokenRowProps {
	token?: TransformedTokenGrouped;
	isLoading?: boolean;
	isInSearchResult?: boolean;
	testId?: string;
}

/**
 * Represents a row in the token table which can comprise one or more `TokenDefinition`s.
 * For example a `TokenRow` can contain one TokenDefinition, or multiple `TokenDefinition`s if the token has extensions (hovered, pressed).
 *
 * Renders as table row.
 *
 * For tokens with extensions, this component is also responsible for rendering the description for the token grouping in the last row of table.
 */
const TokenRow = ({
	token: transformedToken,
	isLoading,
	testId,
	isInSearchResult = false,
}: TokenRowProps) => {
	if (!transformedToken) {
		return null;
	}

	const extensions = transformedToken.extensions || [];
	const hasExtensions = isInSearchResult ? false : extensions.length > 0;
	const tokenGroup = isInSearchResult ? [transformedToken] : [transformedToken].concat(extensions);

	return hasExtensions ? (
		<Fragment>
			{tokenGroup.map((token) => (
				<tr key={token.name} css={tokenRowExtensionsStyles}>
					<TokenDefinition key={token.name} token={token} shouldHideDescription={hasExtensions} />
				</tr>
			))}
			<tr css={[tokenRowStackStyles, rowBorderStyles]}>
				<td css={resetTableDefaultStyles}>
					<Stack space="space.150">
						<TokenDescription transformedToken={transformedToken} isLoading={isLoading} />
						<TokenLifecycle transformedToken={transformedToken} isLoading={isLoading} />
					</Stack>
				</td>
			</tr>
		</Fragment>
	) : (
		<tr css={[tokenRowStackStyles, rowBorderStyles]} data-testid={testId && `${testId}-token-item`}>
			{tokenGroup.map((token) => (
				<TokenDefinition key={token.name} token={token} shouldHideDescription={hasExtensions} />
			))}
		</tr>
	);
};

export default TokenRow;
